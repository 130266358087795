import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import NotificationService from "../../../../../core/services/notification.service";
import LocalStorageService from "../../../../../core/services/localstorage.service";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import {
  GET_MSC_CATEGORIES,
  GET_MSC_CLOUDBILLING_SUMMARY,
  GET_MSC_ENVIRONMENT,
  GET_MSC_TAGS
} from "./graphql/tags.queries";
import { MUTATION_MSC_TAGS } from "./graphql/tags.mutations";
import { DATE_FORMAT } from "@/core/constants/time";
import {
  CLOUD_PROVIDER_ENUM,
  COUNTRY_ENUM,
  MSC_SCALE_ENUM,
  MSC_SERVICE_LEVEL_ENUM,
  MSC_TAG_ENUM
} from "@/core/constants";
import TableDragSelect from "../../../../../view/content/TableDragSelect/TableDragSelect.vue";
import DialogService from "../../../../../core/services/dialog.service";
import { GET_MSC_CUSTOMERS } from "../customers/graphql/customers.queries";
//#region Vee Validation
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import { required } from "vee-validate/dist/rules";
import dayjs from "dayjs";

setInteractionMode("eager");
extend("required", {
  ...required,
  message: "{_field_} is required"
});
//#endregion

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  components: {
    "table-drag-select": TableDragSelect,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      noDataText: "No results found with provided filters, please try again",
      isLoading: false,
      skipQuery: true,
      dialogFormValid: false,
      dialogEditTag: false,
      dialogCloudBillingSummary: false,
      dialogFilter: false,
      newTagValueInput: null,
      menu: false,
      toggleFullscreen: false,
      dateFrom: dayjs().date(1).toISOString().substr(0, 7),
      cloudProviderList: [
        { name: CLOUD_PROVIDER_ENUM.AWS },
        { name: CLOUD_PROVIDER_ENUM.AZURE }
      ],
      countryList: Object.entries(COUNTRY_ENUM).map(([name]) => ({
        name
      })),
      tagOptionList: [
        { name: "billingCode" },
        { name: "contractNumber" },
        { name: "environment" },
        { name: "serviceLevel" }
      ],
      selectedCloudProvider: null,
      selectedCountry: null,
      customerList: [],
      categoryList: [],
      selectedAccount: null,
      selectedCustomer: null,
      selectedCategory: null,
      selectedSubCategory: null,
      selectedEnvironmentOption: null,
      selectedTagOption: null,
      tagList: [],
      selectedTag: {},
      tableHeaders: [],
      selectedCells: {},
      pickerDate: null,
      cloudBillingSummaryList: [],
      subscriptionList: [],
      resourceGroupList: [],
      resourceCategoryList: [],
      resourceSubCategoryList: [],
      environmentList: [],
      serviceLevelList: [],
      resourceNameList: [],
      selectedSubscription: null,
      selectedResourceGroup: null,
      selectedResourceCategory: null,
      selectedResourceSubCategory: null,
      selectedEnvironment: null,
      selectedServiceLevel: null,
      selectedResourceName: null,
      showInconsistentResources: false,
      showUntaggedResources: false
    };
  },
  apollo: {
    customers: {
      client: "connectClient",
      query: GET_MSC_CUSTOMERS,
      variables() {
        return this.getCustomersQueryVariable();
      },
      update(data) {
        let customers = data.finance.managedServicesConnector.customers;
        if (!customers.result) {
          NotificationService.error(
            `${customers.metadata.message} (${customers.metadata.statusCode})`
          );
          return [];
        } else {
          this.customerList = customers.result;
        }

        LocalStorageService.setItem(
          this.getCustomerLocalStorageKey(),
          this.customerList
        );
      },
      result({ networkStatus }) {
        if (networkStatus === 7) {
          this.$apollo.queries.customers.skip = true;
        }
      },
      skip() {
        return this.skipQuery;
      }
    },
    categories: {
      client: "connectClient",
      query: GET_MSC_CATEGORIES,
      variables() {
        return this.getMscCategoriesQueryVariable();
      },
      update(data) {
        let cats = data.finance.managedServicesConnector.categories;
        if (!cats.result) {
          NotificationService.error(
            `${cats.metadata.message} (${cats.metadata.statusCode})`
          );
          return [];
        } else {
          return cats.result;
        }
      },
      result({ networkStatus }) {
        if (networkStatus === 7) {
          this.$apollo.queries.categories.skip = true;
        }
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      skip() {
        return this.skipQuery;
      }
    },
    mscTags: {
      client: "connectClient",
      query: GET_MSC_TAGS,
      variables() {
        return this.getMscTagsQueryVariable();
      },
      update(data) {
        let tags = data.finance.managedServicesConnector.tags;
        if (!tags.result) {
          NotificationService.error(
            `${tags.metadata.message} (${tags.metadata.statusCode})`
          );
          return [];
        } else {
          return this.convertResponseDataToNewObject(tags.result);
        }
      },
      result({ networkStatus }) {
        if (networkStatus === 7) {
          this.$apollo.queries.mscTags.skip = true;
        }
      },
      skip() {
        return this.skipQuery;
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      error(error) {
        NotificationService.error(error);
      }
    },
    cloudBillingSummary: {
      client: "connectClient",
      query: GET_MSC_CLOUDBILLING_SUMMARY,
      variables() {
        return this.getMscCloudBillingSummaryQueryVariable();
      },
      update(data) {
        let summary = data.finance.managedServicesConnector.cloudBillingSummary;
        if (!summary.result) {
          NotificationService.error(
            `${summary.metadata.message} (${summary.metadata.statusCode})`
          );
          this.cloudBillingSummaryList = [];
        } else {
          this.cloudBillingSummaryList = this.getSummaryEnvironments(
            JSON.parse(summary.result)
          );
        }
      },
      result({ networkStatus }) {
        if (networkStatus === 7) {
          this.$apollo.queries.cloudBillingSummary.skip = true;
        }
      },
      skip() {
        return this.skipQuery;
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      error(error) {
        NotificationService.error(error);
      }
    },
    environments: {
      client: "connectClient",
      query: GET_MSC_ENVIRONMENT,
      variables() {
        return this.getMscCategoriesQueryVariable();
      },
      update(data) {
        let envs = data.finance.managedServicesConnector.environments;
        if (!envs.result) {
          NotificationService.error(
            `${envs.metadata.message} (${envs.metadata.statusCode})`
          );
          return [];
        } else {
          return envs.result;
        }
      },
      result({ networkStatus }) {
        if (networkStatus === 7) {
          this.$apollo.queries.environments.skip = true;
        }
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      skip() {
        return this.skipQuery;
      }
    }
  },
  watch: {
    dateFrom(newValue, oldVal) {
      if (newValue !== oldVal) {
        if (this.isFormValid) {
          this.customerSelectionChanged();
        }
      }
    }
  },
  computed: {
    isFormValid: function () {
      let valid =
        this.selectedCloudProvider &&
        this.selectedCountry &&
        this.selectedCustomer &&
        this.dateFrom &&
        !this.isLoading;

      if (
        this.selectedCloudProvider &&
        this.selectedCloudProvider.name === "AWS"
      ) {
        valid &&= this.selectedAccount;
      }
      return valid;
    },
    isCloudProviderAWSSelected() {
      return this.selectedCloudProvider?.name === CLOUD_PROVIDER_ENUM.AWS;
    },
    getFilterResourceCategoryList() {
      this.selectedResourceSubCategory = null;
      if (!this.selectedResourceCategory) {
        return this.resourceSubCategoryList.orderBy(x => x.name);
      }

      return this.resourceSubCategoryList.where(
        x => x.category === this.selectedResourceCategory.name
      );
    }
  },
  methods: {
    getAccountNames() {
      let accounts = [];
      if (
        !this.selectedCloudProvider ||
        !this.selectedCountry ||
        !this.selectedCustomer
      ) {
        return accounts;
      }

      this.customerList.forEach(item => {
        if (this.selectedCustomer.accountNumber === item.accountNumber) {
          item.subAccountInfo.forEach(subAccount => {
            if (subAccount.name.trim() !== "") {
              accounts.push(subAccount);
            }
          });
        }
      });
      return accounts;
    },
    getCustomerLocalStorageKey() {
      return `${this.selectedCloudProvider.name}.${this.selectedCountry.name}.${MSC_SCALE_ENUM.CUSTOMER}`;
    },
    isResourceModified() {
      return this.getResourceTagsChanged().length > 0;
    },
    getTagsByGroup(parentItem) {
      return parentItem["converted_tags"][this.selectedTag.name];
    },
    getCustomersQueryVariable() {
      return {
        filters: {
          addDetails: {
            value: [false]
          },
          cloudProvider: {
            value: [this.selectedCloudProvider.name]
          },
          country: {
            value: [this.selectedCountry.name]
          }
        }
      };
    },
    getMscCategoriesQueryVariable() {
      return {
        filters: {
          cloudProvider: {
            value: [this.selectedCloudProvider.name]
          },
          country: {
            value: [this.selectedCountry.name]
          },
          customerId: {
            value:
              this.selectedCloudProvider.name === "AWS"
                ? [this.selectedAccount.id]
                : [this.selectedCustomer.id]
          },
          date: {
            value: [
              dayjs(this.dateFrom, DATE_FORMAT.MONTH).format(DATE_FORMAT.DAY),
              dayjs(this.dateFrom, DATE_FORMAT.MONTH)
                .endOf("month")
                .format(DATE_FORMAT.DAY)
            ],
            operator: "BETWEEN"
          }
        }
      };
    },
    getMscTagsQueryVariable() {
      let filterOptions = {
        cloudProvider: {
          value: [this.selectedCloudProvider.name]
        },
        country: {
          value: [this.selectedCountry.name]
        },
        customerId: {
          value:
            this.selectedCloudProvider.name === "AWS"
              ? [this.selectedAccount.id]
              : [this.selectedCustomer.id]
        },
        date: {
          value: [
            dayjs(this.dateFrom, DATE_FORMAT.MONTH).format(DATE_FORMAT.DAY),
            dayjs(this.dateFrom, DATE_FORMAT.MONTH)
              .endOf("month")
              .format(DATE_FORMAT.DAY)
          ],
          operator: "BETWEEN"
        }
      };
      if (this.selectedCategory) {
        filterOptions["category"] = {
          value: [this.selectedCategory.category]
        };
      }
      if (this.selectedEnvironmentOption) {
        filterOptions["environment"] = {
          value: [this.selectedEnvironmentOption]
        };
      }
      if (this.selectedSubCategory) {
        filterOptions["subCategory"] = {
          value: [this.selectedSubCategory]
        };
      }
      if (this.selectedTagOption) {
        filterOptions["tag"] = {
          value: [this.selectedTagOption]
        };
      }

      return {
        filters: filterOptions
      };
    },
    getMscCloudBillingSummaryQueryVariable() {
      let filterOptions = {
        cloudProvider: {
          value: [this.selectedCloudProvider.name]
        },
        country: {
          value: [this.selectedCountry.name]
        },
        customerId: {
          value:
            this.selectedCloudProvider.name === "AWS"
              ? [this.selectedAccount.id]
              : [this.selectedCustomer.id]
        },
        date: {
          value: [
            dayjs(this.dateFrom, DATE_FORMAT.MONTH).format(DATE_FORMAT.DAY),
            dayjs(this.dateFrom, DATE_FORMAT.MONTH)
              .endOf("month")
              .format(DATE_FORMAT.DAY)
          ],
          operator: "BETWEEN"
        }
      };
      if (this.selectedCategory) {
        filterOptions["category"] = {
          value: [this.selectedCategory.category]
        };
      }
      if (this.selectedEnvironment) {
        filterOptions["environment"] = {
          value: [this.selectedEnvironment.name]
        };
      }
      if (this.selectedServiceLevel) {
        filterOptions["serviceLevel"] = {
          value: [this.selectedServiceLevel.name]
        };
      }
      if (this.selectedSubCategory) {
        filterOptions["subCategory"] = {
          value: [this.selectedSubCategory]
        };
      }

      return {
        filters: filterOptions
      };
    },
    loadMscTags() {
      this.resetTagList();
      this.$apollo.queries.mscTags.skip = false;
      this.$apollo.queries.mscTags.refetch();
    },
    cloudProviderSelectionChanged() {
      this.selectedCountry = null;
      this.resetCustomerList();
      this.resetTagList();
    },
    countrySelectionChanged() {
      this.resetCustomerList();
      this.resetTagList();
      if (this.selectedCloudProvider && this.selectedCountry) {
        let customers = LocalStorageService.getItem(
          this.getCustomerLocalStorageKey()
        );

        if (customers) {
          this.customerList = customers;
        }
        this.$apollo.queries.customers.skip = false;
        this.$apollo.queries.customers.refetch();
      }
    },
    customerSelectionChanged() {
      if (
        this.selectedCloudProvider &&
        this.selectedCountry &&
        this.selectedCustomer
      ) {
        this.selectedAccount = null;
        this.selectedCategory = null;
        this.selectedSubCategory = null;
        this.selectedEnvironment = null;
        this.selectedTagOption = null;
        this.selectedEnvironmentOption = null;
        this.resetTagList();
        if (this.selectedCloudProvider.name !== "AWS") {
          this.$apollo.queries.categories.skip = false;
          this.$apollo.queries.categories.refetch();
          this.$apollo.queries.environments.skip = false;
          this.$apollo.queries.environments.refetch();
        }
      }
    },
    accountSelectionChanged() {
      if (
        this.selectedCloudProvider &&
        this.selectedCountry &&
        this.selectedCustomer &&
        this.selectedAccount
      ) {
        this.selectedCategory = null;
        this.selectedSubCategory = null;
        this.selectedEnvironment = null;
        this.selectedTagOption = null;
        this.selectedEnvironmentOption = null;
        this.resetTagList();
        this.$apollo.queries.categories.skip = false;
        this.$apollo.queries.categories.refetch();
        this.$apollo.queries.environments.skip = false;
        this.$apollo.queries.environments.refetch();
      }
    },
    tagSelectionChanged() {
      this.createTableHeaders();
    },
    createTableHeaders() {
      this.tableHeaders = [
        {
          text: "Type",
          value: "resourceType",
          divider: true,
          width: 200
        },
        {
          text: "Name",
          value: "name",
          divider: true,
          width: 200
        }
      ];

      if (this.selectedCloudProvider.name === CLOUD_PROVIDER_ENUM.AZURE) {
        this.tableHeaders.unshift({
          text: "Group",
          value: "resourceGroup",
          divider: true,
          width: 200
        });
      }

      let dateEndOfMonth = dayjs(this.dateFrom, DATE_FORMAT.MONTH)
        .endOf("month")
        .get("date");

      for (let i = 1; i <= dateEndOfMonth; i++) {
        this.tableHeaders.push({
          text: ("0" + i).slice(-2),
          value: i.toString(),
          divider: true,
          width: 50,
          align: "center"
        });
      }
    },
    getClasses(item, selectedItems) {
      if (item.isDisabled) {
        return {
          "bg-secondary": true
        };
      }

      const isActive = !!selectedItems.find(selectedItem => {
        let isActive = selectedItem.dataset.item == item.id;

        if (isActive) {
          this.selectedCells[item.id] = item;
        }

        return isActive;
      });

      if (this.selectedTag?.name === MSC_TAG_ENUM.SERVICE_LEVEL) {
        return {
          item: true,
          "bg-success": isActive,
          "bg-info":
            !isActive &&
            item.value === MSC_SERVICE_LEVEL_ENUM.BUSINESS_CRITICAL,
          "bg-danger":
            !isActive && item.value === MSC_SERVICE_LEVEL_ENUM.MISSION_CRITICAL,
          "bg-warning":
            !isActive && item.value === MSC_SERVICE_LEVEL_ENUM.NON_CRITICAL
        };
      }

      return {
        item: true,
        "bg-success": isActive
      };
    },
    cellDoubleClickEvent() {
      if (this.selectedCells.any()) {
        this.dialogEditTag = true;
      }
    },
    dialogCancelButtonClick() {
      this.dialogEditTag = false;
      setTimeout(() => {
        this.newTagValueInput = null;
      }, 300);
    },
    dialogSaveButtonClick() {
      // eslint-disable-next-line max-len
      // Update items back to mscTags datasource, therefore we can keep track of changes when users switching back and forth among tags.
      if (this.dialogFormValid) {
        for (const cell of Object.values(this.selectedCells)) {
          let tagItem = this.mscTags[cell.resourceIndex]["converted_tags"][
            this.selectedTag.name
          ];

          tagItem[cell.tagIndex].isDirty = true;
          tagItem[cell.tagIndex].value = this.newTagValueInput;

          // Update property valueChanged on the other columns to show/hide dot icon in each cell.
          let valueToCompare = null;
          for (const [key, tag] of Object.entries(tagItem)) {
            const value = tag.value;
            if (!valueToCompare) {
              if (!tag.isDisabled && value) {
                valueToCompare = value;
              }
            }

            tagItem[key].valueChanged =
              !tag.isDisabled &&
              valueToCompare !== value &&
              this.selectedTag.name !== "serviceLevel";
          }
        }

        this.dialogCancelButtonClick();
      }
    },
    async saveChangesButtonClick() {
      let message = `Are you sure you want to submit your changes?`;

      if (
        await DialogService.confirm(this, "Confirmation", message, {
          manualControl: true
        })
      ) {
        this.$apollo
          .mutate({
            client: "connectClient",
            mutation: MUTATION_MSC_TAGS,
            variables: {
              input: {
                cloudProvider: this.selectedCloudProvider.name,
                country: this.selectedCountry.name,
                customerId:
                  this.selectedCloudProvider.name === "AWS"
                    ? this.selectedAccount.id
                    : this.selectedCustomer.id,
                resources: this.getResourceTagsChanged()
              }
            }
          })
          .then(res => {
            this.resetItemsChanged();
            if (
              res.data?.managedServicesConnectorTagUpdate?.metadata
                ?.conversationId
            ) {
              let conversationId =
                res.data.managedServicesConnectorTagUpdate.metadata
                  .conversationId;
              // eslint-disable-next-line max-len
              let message = `Conversation ID </br> <b class="font-size-lg">${conversationId}</b></br> </br>Please keep this Conversation ID as a reference for our development team if the sync does not work properly You can contact the development team on Slack: #sentia-dev-sala.`;
              DialogService.updateBodyContent(this, "Complete", message, false);
              setTimeout(() => {
                NotificationService.success(
                  "Successfully submitted your change"
                );
              }, 100);
            }
          });
      }
    },
    getResourceTagsChanged() {
      if (!this.mscTags) {
        return [];
      }

      let resources = [];
      this.mscTags.forEach(item => {
        let tags = {};

        for (const [key, props] of Object.entries(item["converted_tags"])) {
          for (const tagItem of Object.values(props)) {
            if (tagItem.isDirty) {
              if (!tags[key]) {
                tags[key] = [];
              }

              tags[key].push({
                date: tagItem.date,
                value: tagItem.value
              });
            }
          }
        }

        if (Object.keys(tags).length) {
          resources.push({
            id: item.id,
            tags: tags
          });
        }
      });

      return resources;
    },
    convertResponseDataToNewObject(data) {
      this.tagList = [];
      if (data) {
        let dateEndOfMonth = dayjs(this.dateFrom, DATE_FORMAT.MONTH)
          .endOf("month")
          .get("date");

        data.forEach((item, index) => {
          let converted_tags = {};

          for (const tagKey of Object.keys(item.tags)) {
            converted_tags[tagKey] = {};
            let valueToCompare = null;

            for (let i = 0; i < dateEndOfMonth; i++) {
              if (item.tags[tagKey] === null) {
                continue;
              }

              const value = item.tags[tagKey][i];
              const isNotAvailable = value === "N/A";
              if (!valueToCompare) {
                if (!isNotAvailable && value) {
                  valueToCompare = value;
                }
              }

              converted_tags[tagKey][i] = {
                id: index * dateEndOfMonth + i,
                value: isNotAvailable ? "" : value,
                resourceIndex: index,
                tagIndex: i,
                date: `${this.dateFrom}-${("0" + (i + 1)).slice(-2)}`,
                isDirty: false,
                isDisabled: isNotAvailable,
                valueChanged: !isNotAvailable && valueToCompare !== value
              };
            }

            if (!this.tagList.some(ele => ele === tagKey)) {
              this.tagList.push({ name: tagKey });
            }
          }

          let itemIdSplitted = item.id.split("/");
          if (itemIdSplitted.any()) {
            item["subscriptionId"] = itemIdSplitted[2];
          }

          item["resourceType"] = item.category + "/" + item.subCategory;
          item["converted_tags"] = converted_tags;
        });

        this.subscriptionList = data
          .select(x => x.subscriptionId)
          .distinct()
          .select(x => {
            return { name: x };
          });

        this.resourceGroupList = data
          .select(x => x.resourceGroup)
          .distinct()
          .orderBy(x => x)
          .select(x => {
            return { name: x };
          });

        this.resourceCategoryList = data
          .select(x => x.category)
          .distinct()
          .orderBy(x => x)
          .select(x => {
            return { name: x };
          });

        this.resourceSubCategoryList = data
          .select(x => x.resourceType)
          .distinct()
          .orderBy(x => x)
          .select(x => {
            return { category: x.split("/")[0], name: x.split("/")[1] };
          });

        this.environmentList = data
          .selectMany(x => x.tags.environment ?? [])
          .distinct()
          .except(["N/A", null])
          .orderBy(x => x)
          .select(x => {
            return { name: x };
          });

        this.serviceLevelList = data
          .selectMany(x => x.tags.serviceLevel ?? [])
          .distinct()
          .except(["N/A", null])
          .orderBy(x => x)
          .select(x => {
            return { name: x };
          });

        this.resourceNameList = data
          .select(x => x.name)
          .distinct()
          .orderBy(x => x)
          .select(x => {
            return { name: x };
          });
        this.selectedTag = {
          name: this.selectedTagOption ?? this.tagList[0]?.name
        };
        this.createTableHeaders();

        return data;
      }

      return null;
    },
    cloudBillingSummaryButtonClick() {
      this.dialogCloudBillingSummary = true;
      this.cloudBillingSummaryList = [];
      this.$apollo.queries.cloudBillingSummary.skip = false;
      this.$apollo.queries.cloudBillingSummary.refetch();
    },
    dialogCloseButtonClick() {
      this.dialogCloudBillingSummary = false;
    },
    enterKeyListener(evt) {
      if (evt.keyCode === 13 && this.selectedCells) {
        this.cellDoubleClickEvent();
      }
    },
    filterButtonClick() {
      this.dialogFilter = true;
    },
    clearFilterButtonClick() {
      this.resetFilterSelection();
    },
    getSummaryEnvironments(data) {
      let items = [];
      for (const key of Object.keys(data)) {
        let item = data[key];
        let newItem = {
          id: key,
          name: `${key} (${item.quantity})`,
          children: this.getSummaryServiceLevels(item.serviceLevels)
        };

        items.push(newItem);
      }

      return items;
    },
    getSummaryServiceLevels(data) {
      let items = [];
      for (const key of Object.keys(data)) {
        let item = data[key];
        let newItem = {
          id: key,
          name: `${key.toUpperCase()} (${item.quantity})`,
          children: this.getSummaryResourceTypes(item.resourceTypes)
        };

        items.push(newItem);
      }

      return items;
    },
    getSummaryResourceTypes(data) {
      let items = [];
      for (const key of Object.keys(data)) {
        let item = data[key];
        let newItem = {
          id: key,
          name: `${key} (${item.quantity})`,
          children: this.getSummaryResources(item.resources)
        };

        items.push(newItem);
      }

      return items;
    },
    getSummaryResources(data) {
      let items = [];
      for (const key of Object.keys(data)) {
        let item = data[key];
        let newItem = {
          id: key,
          name: `${key} (${item.quantity})`,
          children: item.dates
            ? item.dates.select(x => {
                return { id: x, name: x };
              })
            : []
        };

        items.push(newItem);
      }

      return items;
    },
    getFilteredMscTags() {
      let items = [];
      if (!this.mscTags?.any()) {
        return [];
      }

      items = this.mscTags.where(
        x =>
          (!this.selectedSubscription ||
            x.subscriptionId === this.selectedSubscription.name) &&
          (!this.selectedResourceGroup ||
            x.resourceGroup === this.selectedResourceGroup.name) &&
          (!this.selectedResourceCategory ||
            x.category === this.selectedResourceCategory.name) &&
          (!this.selectedResourceSubCategory ||
            x.subCategory === this.selectedResourceSubCategory.name) &&
          (!this.selectedResourceName ||
            x.name === this.selectedResourceName.name) &&
          (!this.selectedEnvironment ||
            x.tags.environment.includes(this.selectedEnvironment.name)) &&
          (!this.selectedServiceLevel ||
            x.tags.serviceLevel.includes(this.selectedServiceLevel.name)) &&
          (!this.showInconsistentResources ||
            Object.values(x.converted_tags[this.selectedTag.name]).some(
              m => m.valueChanged === true
            )) &&
          (!this.showUntaggedResources ||
            Object.values(x.converted_tags[this.selectedTag.name]).any(
              m => m.value === null
            ))
      );

      this.selectedCells = [];
      return items;
    },
    getNumberOfFilterSelection() {
      let count = 0;
      count = this.selectedSubscription ? count + 1 : count;
      count = this.selectedResourceGroup ? count + 1 : count;
      count = this.selectedResourceCategory ? count + 1 : count;
      count = this.selectedResourceSubCategory ? count + 1 : count;
      count = this.selectedEnvironment ? count + 1 : count;
      count = this.selectedServiceLevel ? count + 1 : count;
      count = this.selectedResourceName ? count + 1 : count;
      count = this.showInconsistentResources ? count + 1 : count;
      count = this.showUntaggedResources ? count + 1 : count;
      return count;
    },
    resetFilterSelection() {
      this.selectedSubscription = null;
      this.selectedResourceGroup = null;
      this.selectedResourceCategory = null;
      this.selectedResourceSubCategory = null;
      this.selectedEnvironment = null;
      this.selectedServiceLevel = null;
      this.selectedResourceName = null;
      this.showInconsistentResources = false;
      this.showUntaggedResources = false;
    },
    resetFilterList() {
      this.subscriptionList = [];
      this.resourceGroupList = [];
      this.environmentList = [];
      this.serviceLevelList = [];
      this.resourceNameList = [];
    },
    resetCustomerList() {
      this.selectedAccount = null;
      this.selectedCategory = null;
      this.selectedSubCategory = null;
      this.customerList = [];
      this.selectedCustomer = null;
    },
    resetTagList() {
      this.mscTags = [];
      this.selectedCells = [];
      this.$refs.tableDragSelectRef.clearSelection();
      this.resetFilterSelection();
      this.resetFilterList();
    },
    resetItemsChanged() {
      for (const resource of Object.values(this.mscTags)) {
        for (const tagGroup of Object.values(resource["converted_tags"])) {
          for (const tagItem of Object.values(tagGroup)) {
            tagItem.isDirty = false;
          }
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Group"
      },
      {
        title: "Navigation Menu Group",
        route: "/group/navigation-menu"
      },
      {
        title: "MSC Tags"
      }
    ]);
  },
  created() {
    document.addEventListener("keyup", this.enterKeyListener);
  },
  destroyed() {
    document.removeEventListener("keyup", this.enterKeyListener);
  }
};
