import gql from "graphql-tag";

const GET_MSC_CATEGORIES = gql`
  query MscCategories($filters: ManagedServicesConnectorCategoriesInput!) {
    finance {
      managedServicesConnector {
        categories(filters: $filters) {
          metadata {
            conversationId
            message
            statusCode
            statusText
          }
          result {
            category
            subCategories
          }
        }
      }
    }
  }
`;

const GET_MSC_TAGS = gql`
  query MscTags($filters: ManagedServicesConnectorTagsInput!) {
    finance {
      managedServicesConnector {
        tags(filters: $filters) {
          metadata {
            conversationId
            message
            statusCode
            statusText
          }
          result {
            category
            id
            name
            subCategory
            resourceGroup
            tags {
              billingCode
              contractNumber
              environment
              serviceLevel
            }
          }
        }
      }
    }
  }
`;

const GET_MSC_CLOUDBILLING_SUMMARY = gql`
  query MscCloudBillingSummary(
    $filters: ManagedServicesConnectorCloudBillingSummaryInput!
  ) {
    finance {
      managedServicesConnector {
        cloudBillingSummary(filters: $filters) {
          metadata {
            conversationId
            message
            statusCode
            statusText
          }
          result
        }
      }
    }
  }
`;

const GET_MSC_ENVIRONMENT = gql`
  query MscEnvironments($filters: ManagedServicesConnectorCategoriesInput!) {
    finance {
      managedServicesConnector {
        environments(filters: $filters) {
          metadata {
            conversationId
            message
            statusCode
            statusText
          }
          result
        }
      }
    }
  }
`;

export {
  GET_MSC_CATEGORIES,
  GET_MSC_TAGS,
  GET_MSC_CLOUDBILLING_SUMMARY,
  GET_MSC_ENVIRONMENT
};
