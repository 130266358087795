import gql from "graphql-tag";

const MUTATION_MSC_TAGS = gql`
  mutation MscTags($input: ManagedServicesConnectorTagUpdateInput!) {
    managedServicesConnectorTagUpdate(input: $input) {
      metadata {
        conversationId
        message
        statusCode
        statusText
      }
      result
    }
  }
`;

export { MUTATION_MSC_TAGS };
